import React, { PropsWithChildren, useRef, useEffect, useState, MutableRefObject } from 'react'
import Draggable from 'react-draggable'
import { useDispatch, useSelector } from 'react-redux'
import { selectPlayer, setMiniMode } from '../../store/video/playerSlice'
import { isMobile } from 'react-device-detect'
import './index.css'
import { useAnalytics } from 'react-video-analytics'
import { ReportAction, useReportMutation, MediaItemDocument } from '../../graphql'
import useLiveNow from '../../utils/hooks/useLiveNow'
import {
  MediaPlayer,
  MediaProvider,
  MediaPlayerInstance,
  MediaProviderAdapter,
  isVideoProvider,
  isHLSProvider,
} from '@vidstack/react'
import VideoLayout from './components/VideoLayout'
import config from '../../config'

const VideoPlayer = ({ children }: PropsWithChildren) => {
  const { status, src, id, position, miniMode } = useSelector(selectPlayer)
  const [mobile, setMobile] = useState(false)
  const setVideoEl = useState<HTMLVideoElement | null>(null)[1]
  const mobileRef = useRef(mobile)

  const dispatch = useDispatch()

  const liveNow = useLiveNow(id)

  const [reportMutation] = useReportMutation()

  const player = useRef<MediaPlayerInstance>(null)
  const videoRef: MutableRefObject<HTMLVideoElement | null> = useRef<HTMLVideoElement | null>(null)

  const onProviderSetup = (provider: MediaProviderAdapter) => {
    if (isVideoProvider(provider) || isHLSProvider(provider)) {
      setVideoEl(provider.video)
      videoRef.current = provider.video
    }
  }

  useAnalytics(videoRef, {
    videoId: id,
    send: async ({ headers, ...metrics }) => {
      delete metrics.__attempts
      if (id && videoRef.current && !(metrics.action === 'error' && !src)) {
        await reportMutation({
          variables: {
            data: {
              contentId: id,
              ...metrics,
              ...headers,
              action: metrics.action as ReportAction,
              error: metrics.error && {
                code: metrics.error.code,
                message: metrics.error.message,
                data: JSON.stringify(metrics.error.data),
                source: metrics.error.source?.toString(),
              },
              totalDuration: Math.round(videoRef.current.duration),
            },
          },
          update: (cache) => {
            if (
              metrics.action === 'time' ||
              metrics.action === 'pause' ||
              metrics.action === 'complete' ||
              metrics.action === 'seek'
            ) {
              cache.updateQuery(
                {
                  query: MediaItemDocument,
                  variables: {
                    data: {
                      id,
                    },
                  },
                },
                (data) => ({
                  mediaItem: {
                    ...data.mediaItem,
                    viewer: {
                      __typename: 'Viewer',
                      ...data.mediaItem.viewer,
                      lastViewedAt: new Date(metrics.timestamp),
                      lastPosition: metrics.position,
                      progress: videoRef.current
                        ? metrics.position / Math.round(videoRef.current.duration)
                        : data.mediaItem.viewer.progress,
                    },
                  },
                }),
              )
            }
          },
        })
      }
    },
  })

  const playerStyle =
    status !== 'stopped' && src !== null
      ? {
          opacity: 1,
          zIndex: isMobile ? -1000 : 1000,
          pointerEvents: miniMode ? ('none' as const) : ('auto' as const),
        }
      : {
          opacity: 0,
          zIndex: -1000,
          pointerEvents: miniMode ? ('none' as const) : ('auto' as const),
        }

  const onResize = () => {
    if (window.innerWidth <= 480) {
      if (!mobileRef.current) {
        setMobile(true)
      }
    } else {
      if (mobileRef.current) {
        setMobile(false)
      }
    }
  }

  useEffect(() => {
    mobileRef.current = mobile
  }, [mobile])

  useEffect(() => {
    if (config.mediaBrand) {
      document.documentElement.style.setProperty('--media-brand', config.mediaBrand)
    }
    window.addEventListener('resize', onResize)
    setTimeout(() => {
      try {
        onResize()
      } catch (e) {
        console.log(e)
      }
    }, 50)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  const onCanPlayThrough = async () => {
    if (status === 'playing') {
      if (player.current) {
        const videoPlayer = player.current
        if (
          position &&
          position > 1 &&
          // Update the video player time if it is more than 10 seconds off the currently saved position
          (videoPlayer.currentTime > position + 5 || videoPlayer.currentTime < position - 5)
        ) {
          videoPlayer.currentTime = position
        } else {
          videoPlayer
            .play()
            .then(() => {
              console.log('Playing video')
            })
            .catch((e) => {
              console.error(e)
            })
        }
      }
    }
  }

  return (
    <div className={'media-player-bounds'}>
      <div className={'mini-player-container'}>{children}</div>
      <div className={'media-player-container'} style={playerStyle}>
        <Draggable bounds={'parent'}>
          <div className={'media-player-wrapper'}>
            <MediaPlayer
              onError={(e) => {
                console.log(e)
              }}
              onHlsError={(e) => {
                console.log(e)
              }}
              load={'eager'}
              currentTime={position || undefined}
              streamType={liveNow ? 'live:dvr' : 'on-demand'}
              onCanPlayThrough={onCanPlayThrough}
              ref={player}
              src={src || ''}
              onProviderSetup={onProviderSetup}>
              <MediaProvider
                onError={(e) => {
                  console.log(e)
                }}
              />
              <VideoLayout onMiniModeChange={(value) => dispatch(setMiniMode(value))} />
            </MediaPlayer>
          </div>
        </Draggable>
      </div>
    </div>
  )
}

export default VideoPlayer
