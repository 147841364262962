import { client } from './index'
import { GlobalDocument } from '../graphql'
import LiveNowService, { fetchLiveNow } from './LiveNowService'

const pollInterval = 30000

function checkLiveNow(fetchPolicy: 'network-only' | 'cache-only' = 'network-only') {
  fetchLiveNow(fetchPolicy).then(({ data: { liveNow } }) => {
    if (liveNow?.length) {
      LiveNowService.start()
    } else {
      LiveNowService.stop()
    }
  })
}

function start() {
  checkLiveNow()

  const subscription = client
    ?.watchQuery({
      query: GlobalDocument,
      fetchPolicy: 'network-only',
      pollInterval,
    })
    ?.subscribe({
      next: ({ data }) => {
        if (data.global?.liveUpcoming) {
          LiveNowService.start()
        } else {
          checkLiveNow('cache-only')
        }
      },
      error: () => {
        // Retry service on error
        subscription?.unsubscribe()
        start()
      },
    })
}

export default {
  start,
}
